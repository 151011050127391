<header class="header header--sidebar">
    <div class="header__items mr-auto">
        <div class="header__title dots dots--long">{{ selectedRecord?.firstName }} {{ selectedRecord?.lastName }}</div>
        <div class="header__subtitle">Users</div>
    </div>
    <div class="header__items">
        <button mat-icon-button color="accent" (click)="closeSidenav()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</header>

<div class="flex justify-center mt-5">
    <img *ngIf="selectedRecord?.image as image else placeholder" class="rounded-full w-36 h-36" [src]="image"
        alt="User image" />
    <ng-template #placeholder>
        <div class="rounded-full w-36 h-36 bg-gray-200"></div>
    </ng-template>
</div>

<div *ngIf="selectedRecord" class="sidenav__list">
    <form *ngIf="form" [formGroup]="form">
        <div class="sidenav__list-row">
            <div class="sidenav__list-col sidenav__list-col--key">Username</div>
            <div class="sidenav__list-col">
                <mat-form-field class="list-input">
                    <textarea [placeholder]="inputPlaceholder" cdkTextareaAutosize formControlName="userName"
                        matInput></textarea>
                </mat-form-field>
                <div class="sidenav__list-errors">
                    <div *ngIf="form.controls.userName?.errors?.maxlength" class="sidenav__list-error">
                        Username must not exceed {{ form.controls.userName?.errors?.maxlength.requiredLength }}
                        characters
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div class="sidenav__list-row">
            <div class="sidenav__list-col sidenav__list-col--key">First Name</div>
            <div class="sidenav__list-col">
                <mat-form-field class="list-input">
                    <textarea matInput [placeholder]="inputPlaceholder" cdkTextareaAutosize
                        formControlName="firstName"></textarea>
                </mat-form-field>
                <div class="sidenav__list-errors">
                    <div *ngIf="form.controls.firstName?.errors?.maxlength" class="sidenav__list-error">
                        First Name must not exceed {{ form.controls.firstName?.errors?.maxlength.requiredLength }}
                        characters
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div class="sidenav__list-row">
            <div class="sidenav__list-col sidenav__list-col--key">Last Name</div>
            <div class="sidenav__list-col">
                <mat-form-field class="list-input">
                    <textarea [placeholder]="inputPlaceholder" cdkTextareaAutosize formControlName="lastName"
                        matInput></textarea>
                </mat-form-field>
                <div class="sidenav__list-errors">
                    <div *ngIf="form.controls.lastName?.errors?.maxlength" class="sidenav__list-error">
                        Last Name must not exceed {{ form.controls.lastName?.errors?.maxlength.requiredLength }}
                        characters
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div class="sidenav__list-row" *ngIf="!isPartnerUpdate">
            <div class="sidenav__list-col sidenav__list-col--key">Company name</div>
            <div class="sidenav__list-col">
                <mat-form-field class="list-input">
                    <textarea matInput [placeholder]="inputPlaceholder" cdkTextareaAutosize
                        formControlName="companyName"></textarea>
                </mat-form-field>
                <div class="sidenav__list-errors">
                    <div *ngIf="form.controls.companyName?.errors?.maxlength" class="sidenav__list-error">
                        Company Name must not exceed {{ form.controls.companyName?.errors?.maxlength.requiredLength }}
                        characters
                    </div>
                </div>
            </div>
        </div>
        <mat-divider *ngIf="!isPartnerUpdate"></mat-divider>

        <div class="sidenav__list-row" *ngIf="!isPartnerUpdate">
            <div class="sidenav__list-col sidenav__list-col--key">Title</div>
            <div class="sidenav__list-col">
                <mat-form-field class="list-input">
                    <textarea [placeholder]="inputPlaceholder" cdkTextareaAutosize formControlName="title"
                        matInput></textarea>
                </mat-form-field>
                <div class="sidenav__list-errors">
                    <div *ngIf="form.controls.title?.errors?.maxlength" class="sidenav__list-error">
                        Title must not exceed {{ form.controls.title?.errors?.maxlength.requiredLength }} characters
                    </div>
                </div>
            </div>
        </div>
        <mat-divider *ngIf="!isPartnerUpdate"></mat-divider>

        <div class="sidenav__list-row">
            <div class="sidenav__list-col sidenav__list-col--key">Email</div>
            <div class="sidenav__list-col">
                <mat-form-field class="list-input">
                    <textarea [placeholder]="inputPlaceholder" cdkTextareaAutosize formControlName="email"
                        matInput></textarea>
                </mat-form-field>
                <div class="sidenav__list-errors">
                    <div *ngIf="form.controls.email?.errors?.required" class="sidenav__list-error">Email is required
                    </div>
                    <div *ngIf="form.controls.email?.errors?.email" class="sidenav__list-error">Invalid Email</div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div class="sidenav__list-row">
            <div class="sidenav__list-col sidenav__list-col--key">Tenant Id</div>
            <div class="sidenav__list-col">
                <input #tenantIdMatSelect [matTooltip]="tenantIdMatSelect.value" matInput [placeholder]="selectPlaceholder"
                    (click)="clearValue(form.controls.tenantId)" [matAutocomplete]="auto" class="cursor-pointer"
                    formControlName="tenantId">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredTenants$ | async | orderBy: 'name' " [value]="option">
                        {{ option.name }} - {{ option.tenantId }}
                    </mat-option>
                </mat-autocomplete>
                <div class="sidenav__list-errors">
                    <div *ngIf="form.controls.tenantId?.errors?.required" class="sidenav__list-error">Tenant Id is
                        required</div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div class="sidenav__list-row" *ngIf="!isPartnerUpdate">
            <div class="sidenav__list-col sidenav__list-col--key">Partner</div>
            <div class="sidenav__list-col">
                <mat-form-field class="list-input">
                    <textarea [placeholder]="inputPlaceholder" cdkTextareaAutosize formControlName="partner"
                        matInput></textarea>
                </mat-form-field>
                <div class="sidenav__list-errors">
                    <div *ngIf="form.controls.partner?.errors?.maxlength" class="sidenav__list-error">
                        Partner Name must not exceed {{ form.controls.partner?.errors?.maxlength.requiredLength }}
                        characters
                    </div>
                </div>
            </div>
        </div>
        <mat-divider *ngIf="!isPartnerUpdate"></mat-divider>

        <div class="sidenav__list-row" *ngIf="!isPartnerUpdate">
            <div class="sidenav__list-col sidenav__list-col--key">Type</div>
            <div class="sidenav__list-col">
                <mat-select [placeholder]="selectPlaceholder" class="list-select" formControlName="type">
                    <mat-option [value]="0">Partner</mat-option>
                    <mat-option [value]="1">Client</mat-option>
                    <mat-option [value]="2">Joynd</mat-option>
                </mat-select>
            </div>
        </div>
        <mat-divider *ngIf="!isPartnerUpdate"></mat-divider>

        <div class="sidenav__list-row">
            <div class="sidenav__list-col sidenav__list-col--key">Roles</div>
            <div class="sidenav__list-col">
                <mat-select [placeholder]="selectPlaceholder" class="list-select" formControlName="roles" multiple>
                    <mat-select-trigger> {{ getSelectTriggerText(form.controls.roles?.value?.join(',')) }}
                    </mat-select-trigger>
                    <mat-option #matOption *ngFor="let option of roles | orderBy: 'name'" [value]="option.name"
                        (click)="onRoleSelectCheckEmail(option.name, matOption.selected);">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
                <div class="sidenav__list-errors">
                    <div *ngIf="form.controls.roles?.errors?.required" class="sidenav__list-error">
                        At least one role is required
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div class="sidenav__list-row">
            <div class="sidenav__list-col sidenav__list-col--key">Comment</div>
            <div class="sidenav__list-col">
                <mat-form-field class="list-input">
                    <textarea appHotkeyComment [placeholder]="inputPlaceholder" cdkTextareaAutosize
                        formControlName="comment" matInput></textarea>
                </mat-form-field>
                <div class="sidenav__list-errors">
                    <div *ngIf="form.controls.comment?.errors?.maxlength" class="sidenav__list-error">
                        Comment must not exceed {{ form.controls.comment?.errors?.maxlength.requiredLength }} characters
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div class="sidenav__list-row">
            <div class="sidenav__list-col sidenav__list-col--key">Status</div>
            <div class="sidenav__list-col">
                <mat-select [placeholder]="selectPlaceholder" class="list-select" formControlName="status">
                    <mat-option [value]="userStatus.Active">Active</mat-option>
                    <mat-option [value]="userStatus.Inactive">Inactive</mat-option>
                </mat-select>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div class="sidenav__list-row">
            <div class="sidenav__list-col sidenav__list-col--key">Last Login</div>
            <div class="sidenav__list-col">{{ selectedRecord?.lastLogin | date: 'medium' }}</div>
        </div>
    </form>
    <app-save-panel (cancel)="closeSidenav()" (save)="emitSave()" *ngIf="form.dirty"></app-save-panel>
</div>

<button mat-button color="accent" class="block mx-auto mb-4" (click)="sendResetPassword()">Send reset password
    Email</button>