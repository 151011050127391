import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ExpandButtonService } from '../core/services/shell/expand-button.service';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { User } from '../core/models/user/user.model';
import { AppState } from '../store';
import { Store, select } from '@ngrx/store';
import { selectCurrentUser } from '../components/user-profile/state/current-user.selectors';
import { Permissions } from '../core/authorization/permissions';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellComponent implements OnInit {
    public expandMainSidenav = true;
    public overlap$: Subject<boolean>;
    public isSetupQuestionsRouteActivated$ = new BehaviorSubject<boolean>(false);
    public setupQuestionsRoute = 'setup-questions';
    public isPartner$: Observable<boolean>

    constructor(private store: Store<AppState>, private route: ActivatedRoute, private router: Router, private expandButtonService: ExpandButtonService) { }

    ngOnInit(): void {
        const user$ = this.store.pipe(select(selectCurrentUser));
        this.isPartner$ = user$.pipe(switchMap((user) =>
            of(user.roles.some(role => role.availableForPartners))
        ));
        this.isPartner$.subscribe()
        this.overlap$ = this.expandButtonService.overlap$;
        this.isSetupQuestionsRouteActivated$.next(this.route.firstChild.snapshot.url.join('/') === this.setupQuestionsRoute);
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                tap(() => {
                    this.isSetupQuestionsRouteActivated$.next(
                        this.route.firstChild.snapshot.url.join('/') === this.setupQuestionsRoute
                    );
                })
            )
            .subscribe();
    }
}
