<div mat-dialog-title class="flex justify-between items-center">
  <h2 class="grow">{{ helpTopic.name }}</h2>
  <span class="flex-auto"></span>
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="sidenav__list">
    <div class="sidenav__list-row">
      <div class="sidenav__list-col sidenav__list-col--key">Quick Text</div>
      <div class="sidenav__list-col sidenav__list-col">{{ helpTopic.description }}</div>
    </div>

    <mat-divider></mat-divider>
    <div class="sidenav__list-row">
      <div class="sidenav__list-col sidenav__list-col--key">Description</div>
      <div class="sidenav__list-col sidenav__list-col">
        <p class="whitespace-pre-wrap max-w-full break-words" [innerHTML]="helpTopic.narrative | safeHTML"></p>
      </div>
    </div>

    <mat-divider></mat-divider>
    <div class="sidenav__list-row">
      <div class="sidenav__list-col sidenav__list-col--key">Topic Section</div>
      <div class="sidenav__list-col sidenav__list-col">{{ helpTopic.helpTopicSectionCode }}</div>
    </div>
  </div>

  <div class="sidenav__list-row">
    <div class="sidenav__list-col sidenav__list-col--key"></div>
    <div class="sidenav__list-col sidenav__list-col flex justify-end">
      <a mat-flat-button target="_blank" color="primary" routerLink="/help-topics-user">
        Search More
      </a>
    </div>
  </div>
</mat-dialog-content>