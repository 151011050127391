import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { selectCurrentUser } from 'src/app/components/user-profile/state/current-user.selectors';
import { Permissions } from 'src/app/core/authorization/permissions';
import { PartnerEngagementPage } from 'src/app/core/models/partner-engagement-page/partner-engagement-page.model';
import { UserProfile } from 'src/app/core/models/user/user-profile.model';
import { PartnerEngagementPagesService } from 'src/app/core/services/partner-engagement-pages/partner-engagement-pages.service';
import { AppState } from 'src/app/store';

@Component({
    selector: 'app-partner-menu',
    templateUrl: './partner-menu.component.html',
    styleUrls: ['./partner-menu.component.scss']
})
export class PartnerMenuComponent implements OnInit {
    public user$: Observable<UserProfile>;
    public pages$: Observable<Array<PartnerEngagementPage>>;

    @Input() public expandMainSidenav: boolean;

    public get permissions(): typeof Permissions {
        return Permissions;
    }

    constructor(private store: Store<AppState>, private partnerEngagementPagesService: PartnerEngagementPagesService) { }

    ngOnInit(): void {
        this.user$ = this.store.pipe(select(selectCurrentUser));

        this.pages$ = this.partnerEngagementPagesService.getAvailablePartnerEngagementPages().pipe(
            shareReplay(1),
        )
    }

    public showMenuItem(user: UserProfile, requiredPermission: string): boolean {
        return user?.roles.some((role) => role.permissions.some((permissions) => permissions === requiredPermission));
    }
}
